import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const IntroContainer = styled.div`
  // background: #c7dad4;
  // #dadada
  background: ${(props) => (props.background ? props.background : "#ebebeb")};
  min-height: 800px;
  min-height: 100vh;
  //color: ${(props) => (props.color ? props.color : "#333d29")};
`;

export const IntroSpacing = styled.div`
  padding: 5rem 0 10rem;
  margin-top: 5rem;

  @media (max-width: 650px){
    padding 2.5rem 0 10rem;
  }
`;

export const HeroTitle = styled.h1`
  text-align: center;
  font-size: 3rem;
`;

export const ImgContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
`;

export const Booking = styled.div`
  position: absolute;
  top: 60px;
  right: 250px;

  @media (max-width: 650px) {
    display: none;
  }

  @media (max-width: 1500px) {
    right: 50px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: center;
  padding-top: 2rem;
  z-index: 2;
`;

export const Links = styled.nav`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 2;

  & a:hover {
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.75;
  }

  & a {
    // background: rgba(0, 0, 0, 0.1);
    padding: 0.15rem 0.75rem;
    border-radius: 3px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const GreenButton = styled.a.attrs((props) => ({
  href: "https://bokning.voady.se/ellislit/categories",
  target: "_blank",
}))`
  border: 0;
  padding: 10px 25px;
  color: white;
  background: #333d29;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 1.1rem;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
`;

export const HeroContainer = styled.div`
  max-width: 50%;
  margin-top: 15rem;
  position: relative;
  z-index: 2;

  @media (max-width: 500px) {
    margin-top: 15rem;
    max-width: 100%;
  }
`;

export const MainTitle = styled.p`
  font-size: 3rem;
  text-align: center;
  margin: 0;
  z-index: 2;
  position: relative;
  letter-spacing: 10px;
`;

export const IndexHeroTitle = styled.h1`
  font-size: 3rem;

  @media (max-width: 800px) {
    font-size: 2rem;
  }
`;

export const RoseContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 99vw;
  overflow: hidden;
`;

export const RosesImg = styled(GatsbyImage)`
  position: absolute;
  top: -190px;
  left: -190px;
  width: 700px;
  transform: rotate(150deg);
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: 1350px) {
    width: 500px;
    top: -130px;
    left: -130px;
  }

  @media (max-width: 1050px) {
    width: 400px;
    top: -100px;
    left: -100px;
  }

  @media (max-width: 900px) {
    opacity: 0;
  }

  // @media (max-width: 500px) {
  //   transform: rotate(0deg);
  //   top: 400px;
  //   left: 0px;
  // }

  @media (max-width: 900px) {
    margin: 0 auto;
    transform: rotate(0deg);
    top: 90px;
    left: 0px;
    right: 0px;
    opacity: 1;
    // transform: scale(1.2);
  }

  @media (max-width: 650px) {
    margin: 0 auto;
    transform: rotate(0deg);
    top: 20px;
    left: 0px;
    right: 0px;
    opacity: 1;
    // transform: scale(1.2);
  }
`;

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 0;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

export const RoundedImg = styled(GatsbyImage)`
  border-radius: 1%;
  max-height: 500px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const OneColumn = styled.div`
  text-align: center;
  // background: #f6f7f9;
  background: #ebebeb;

  div {
    max-width: 650px;
  }

  h2 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1.5rem;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;
export const Review = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 280px;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.25rem;
  }

  img {
    max-height: 155px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
`;

export const BookingContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const PhoneNumber = styled.a`
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  margin-top: 22px;
  color: #333d29;
  font-family: "Playfair Display", serif;
  margin-left: 1rem;
`;

export const RosesContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
`;

export const GalleryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }

  @media (min-width: 950px) {
    grid-template-columns: ${(props) =>
      props.gridColumns ? props.gridColumns : "1fr 1fr 1fr"};
  }

  @media (max-width: 600px) {
    max-width: 95%;
  }

  @media (max-width: 1250px) {
    max-width: 90%;
  }
`;

export const GalleryImg = styled(GatsbyImage)`
  border-radius: 1%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
