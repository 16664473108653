import React from "react";
import "./layout.css";
import { slide as Menu } from "react-burger-menu";

import { Link } from "gatsby";

export default function Layout({ children }) {
  return (
    <>
      <Menu right={true} className="mobile-only">
        <Link className="menu-item" to="/">
          Hem
        </Link>
        <a
          className="menu-item"
          target="_blank"
          href="https://bokning.voady.se/ellislit/categories"
        >
          Boka
        </a>
        <Link className="menu-item" to="/priser">
          Priser
        </Link>
        {/* <Link className="menu-item" to="/galleri">
          Galleriet
        </Link> */}
      </Menu>
      {children}
    </>
  );
}
