import { Link } from "gatsby";
import * as React from "react";
import {
  Booking,
  GreenButton,
  Links,
  MainTitle,
  TitleContainer,
} from "../components/styles/styles";
import Container from "./container";
import styled from "styled-components";

const TitleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Navbar = () => {
  return (
    <Container>
      <TitleContainer>
        <TitleLink to="/">
          <MainTitle className="title-font">Ellies.</MainTitle>
        </TitleLink>
        <Booking>
          <GreenButton>Boka nu</GreenButton>
        </Booking>
      </TitleContainer>
      <Links>
        <Link className="green-text" to="/">
          Hem
        </Link>
        <a
          className="green-text"
          target="_blank"
          href="https://bokning.voady.se/ellislit/categories"
        >
          Boka
        </a>
        <Link className="green-text" to="/priser">
          Priser
        </Link>
        {/* <Link className="green-text" to="/galleri">
          Galleriet
        </Link> */}
      </Links>
    </Container>
  );
};

export default Navbar;
