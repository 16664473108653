import React from "react";
import styled from "styled-components";

const ContainerDiv = styled.div`
  width: 80%;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: 400px) {
    width: 90%;
  }
`;

export default function Container({ children }) {
  return <ContainerDiv>{children}</ContainerDiv>;
}
